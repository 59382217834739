import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import View from '../components/View'
import Status from '../components/Status'
import Blobs from '../components/Blobs'

const IndexPage = () => (
  <Layout>
    <Status />
    <View title="Weave Sales Tools">
      <p>
        This is a page used by Weave Employees. If you came here by mistake, feel free to check out our website:
      {` `}
        <a href="https://www.getweave.com">GetWeave.com</a>
        {` `}
      .
    </p>
      <p>
        Go to the
      {` `}
        <Link to="/app/roi">sales tools</Link>.
    </p>
    </View>
    <Blobs />
  </Layout>
)

export default IndexPage
